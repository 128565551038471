<template>
  <span class="badge bg-danger p-1" v-if="target === 'banker'">Banker</span>
  <span class="badge bg-primary p-1" v-if="target === 'player'">Player</span>
  <span class="badge bg-success p-1" v-if="target === 'tie'">Tie</span>
  <span class="badge badge-soft-primary p-1" v-if="target === 'player pair' || target === 'playerpair'">Player Pair</span>
  <span class="badge badge-soft-primary p-1" v-if="target === 'PlayerPair_Lightning'">Player-PL</span>
  <span class="badge badge-soft-danger p-1" v-if="target === 'banker pair' || target === 'bankerpair'">Banker Pair</span>
  <span class="badge badge-soft-primary p-1" v-if="target === 'BankerPair_Lightning'">Banker-PL</span>
  <span class="badge badge-soft-pink p-1" v-if="target === 'perfect pair' || target === 'perfectpair'">Perfect Pair</span>
  <span class="badge badge-soft-secondary p-1" v-if="target === 'either pair' || target === 'eitherpair'">Either Pair</span>
  <span class="badge badge-soft-info p-1" v-if="target === 'player bonus' || target === 'playerbonus'">Player Bonus</span>
  <span class="badge badge-soft-info p-1" v-if="target === 'Player_Lightning'">Player-L</span>
  <span class="badge badge-soft-warning p-1" v-if="target === 'banker bonus' || target === 'bankerbonus'">Banker Bonus</span>
  <span class="badge badge-soft-warning p-1" v-if="target === 'Banker_Lightning'">Banker-L</span>
  <span class="badge bg-danger p-1" v-if="target === 'super 6' || target === 'supersix'">Super 6</span>
  <span class="badge bg-dark p-1" v-if="target === 'banker_fee'">Banker Fee</span>
  <span class="badge bg-dark p-1" v-if="target === 'player_fee'">Player Fee</span>
  <span class="badge bg-dark p-1" v-if="target === 'tie_fee'">Tie Fee</span>
  <span class="badge bg-dark p-1" v-if="target === 'bankerpair_fee'">BP Fee</span>
  <span class="badge bg-dark p-1" v-if="target === 'playerpair_fee'">PP Fee</span>
</template>
<script>
export default {
  props: {
    target: String
  },
  methods: {

  },
  computed: {

  }
}
</script>
