<template>
  <div class="dropdown">
    <input type="text" class="form-control dropdown-toggle" data-bs-toggle="dropdown" v-model="selectedGameName" @keyup="typeGameName" placeholder="게임" />
    <ul class="dropdown-menu">
      <li v-for="game in games" :key="'game-' + game._id" v-show="selectedGameIDS.length === 0 || selectedGameIDS.indexOf(game._id) > -1" :class="{'bg-warning': this.selectedGameId === game._id}">
        <a class="dropdown-item" href="javascript:void(0)" @click="selectGame(game)">
          <img :src="game.image" class="img-thumbnail" style="width: 32px;" v-if="game.image" :alt="game.names[$store.getters['auth/user'].language]" /> {{ game.names[$store.getters['auth/user'].language] }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {

  },
  data() {
    return {
      selectedGameName: '',
      selectedGameId: 0,
      selectedGameIDS: [],
      games: 0,
    }
  },
  mounted() {

  },
  methods: {
    async getGames(id) {
      const result = await this.$API.setting.games({ search: { vendor: id }, sort: { sort: 1 }, limit: 1000 })
      if (result.code !== 0) {
        return alert(result.msg)
      }
      this.games = result.documents
    },
    selectGame(game) {
      this.selectedGameIDS = []
      this.selectedGameId = game._id
      this.$emit('selectGames', game._id)
      this.selectedGameName = game.names[this.$store.getters['auth/user'].language]
    },
    typeGameName() {
      this.selectedGameId = 0
      this.selectedGameIDS = []
      if (this.selectedGameName.trim() === '') return
      for(const key1 in this.games) {
        const game = this.games[key1]
        if (game.names[this.$store.getters['auth/user'].language].toLowerCase().indexOf(this.selectedGameName.toLowerCase()) > -1) {
          this.selectedGameIDS.push(game._id)
        }
      }
    }
  },
  watch: {

  }
}
</script>

<style scoped>
  .dropdown-menu {
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 300px);
    /* max-height: 500px; */
  }
</style>
